<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card max-width="600px" class="mx-auto my-12 px-6 py-4" elevation="6">
      <v-card-title class="mt-4">{{ `${personName}，你好！` }}</v-card-title>
      <v-divider></v-divider>
      <div v-if="showSelfPickPanel">
        <v-card-title class="mt-4">自选测评</v-card-title>
        <v-card-text>
          <v-subheader>
            点击选择需要测量的量表（可多选）。量表的答题顺序就是点击选中的顺序。
          </v-subheader>
          <v-list>
            <v-list-item-group
              color="primary"
              multiple
              v-model="selectedLbGuids"
            >
              <v-list-item
                v-for="lb in selfPickLbList"
                :key="lb.lbGuid"
                :value="lb.lbGuid"
                :disabled="isStatusCompleted(lb.status)"
                class="mt-2"
              >
                {{ `${lb.lbDispName} ${getLbStatusText(lb.status)}` }}
                <v-spacer></v-spacer>
                {{ getLbOrderText(lb.lbGuid) }}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-4"
            depressed
            @click="confirmOpenLbGroup"
            :disabled="!selectedLbGuids.length"
          >
            选好了
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
      </div>
      <div>
        <v-card-title class="mt-4">待完成的测评</v-card-title>
        <v-card-text>
          <v-subheader>
            以下是您待完成的量表，可直接点击“开始此项测评”开始。
          </v-subheader>
          <v-expansion-panels
            accordion
            multiple
            v-model="lbGroupStatusPanelVal"
          >
            <v-expansion-panel
              v-for="lbGroup in lbGroupStatusList"
              :key="lbGroup.guid"
            >
              <v-expansion-panel-header>
                <span>{{ lbGroup.lbGroupName }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                  <v-list-item
                    v-for="lb in lbGroup.lbStatusList"
                    :key="lb.lbGuid"
                    :disabled="isStatusCompleted(lb.status)"
                  >
                    {{ lb.lbDispName }}
                    <v-spacer></v-spacer>
                    {{ getLbStatusText(lb.status) }}
                  </v-list-item>
                </v-list>
                <div class="text-right">
                  <v-btn
                    color="primary"
                    depressed
                    :disabled="isAllStatusCompleted(lbGroup.lbStatusList)"
                    @click="
                      pickOneGroupLbGroup(lbGroup.guid, lbGroup.lbStatusList)
                    "
                  >
                    开始此项测评
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </div>
    </v-card>
    <AppDialog
      v-model="isShowConfirmDialog"
      title="确认测评项目"
      color="success"
      action-icon="mdi-check"
      action-text="确定"
      @confirm="startLbTests"
      @closed="startLbTestsClosed"
    >
      <div class="mx-6">
        <div class="mb-2">
          答题顺序：
        </div>
        <ol class="ml-4">
          <li v-for="(lb, idx) in selectedLbList" :key="idx">
            {{ lb.lbDispName }}
          </li>
        </ol>
      </div>
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import { mapGetters } from "vuex";
import {
  fetchPersonInfoInternal,
  fetchLbGroupStatusList,
  fetchOpenLbGroupStatus,
  fetchIsContinuousTest
} from "@/api/sca";
import { caseStatusDict } from "@/utils/constants/case";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog
  },

  data() {
    return {
      isLoading: false,
      errorMsg: "",
      personName: "",
      lbGroupStatusList: [],
      selfPickLbList: [],
      lbGroupStatusPanelVal: [],
      selectedLbGuids: [],
      selectedLbList: [],
      selectedLbGroupGuid: "",
      isShowConfirmDialog: false
    };
  },

  computed: {
    ...mapGetters({
      // 登录的 personGuid
      personGuid: "sca/personGuid",
      // OpenLbGroup 的 Guid，如果在这个页面中选择了其他类型的 LbGroup，则替换成选中的 LbGroupGuid
      lbGroupGuid: "sca/lbGroupGuid"
    }),
    showSelfPickPanel() {
      return this.selfPickLbList && this.selfPickLbList.length;
    }
  },

  methods: {
    async getPersonInfoInternal() {
      try {
        let personInfoFound = await fetchPersonInfoInternal(this.personGuid);
        this.personName = personInfoFound.name;
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async getLbGroupStatusList() {
      try {
        // 添加团体测评列表
        this.lbGroupStatusList = await fetchLbGroupStatusList(this.personGuid);
        // 添加连续测试的开放测量
        if (this.lbGroupGuid) {
          let openLbGroupStatusList = await fetchOpenLbGroupStatus(
            this.lbGroupGuid,
            this.personGuid
          );
          if (openLbGroupStatusList && openLbGroupStatusList.length) {
            let isContTest = await fetchIsContinuousTest(this.lbGroupGuid);
            if (isContTest) {
              this.lbGroupStatusList.push(openLbGroupStatusList[0]);
            } else {
              this.selfPickLbList.push(
                ...openLbGroupStatusList[0].lbStatusList
              );
            }
          }
        }
        // 默认把所有的 ExpansionPanel 都打开
        this.lbGroupStatusPanelVal = [...[...this.lbGroupStatusList].keys()];
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    isStatusCompleted(lbStatus) {
      if (lbStatus && lbStatus.name) {
        return lbStatus.name === caseStatusDict.completed.value;
      }
      return false;
    },
    isAllStatusCompleted(lbStatusList) {
      if (lbStatusList && lbStatusList.length) {
        let incompletedIdx = lbStatusList.findIndex(
          ls => !ls.status || ls.status.name !== caseStatusDict.completed.value
        );
        return incompletedIdx < 0;
      }
      return true;
    },
    getLbStatusText(lbStatus) {
      if (lbStatus && lbStatus.name && caseStatusDict[lbStatus.name]) {
        return caseStatusDict[lbStatus.name].text;
      }
      return "";
    },
    getLbOrderText(lbGuid) {
      let selectedIndex = this.selectedLbGuids.indexOf(lbGuid);
      if (selectedIndex > -1) {
        return `答题顺序：${selectedIndex + 1}`;
      }
      return "";
    },
    confirmOpenLbGroup() {
      this.selectedLbList = this.selectedLbGuids
        .map(lbGuid => this.selfPickLbList.find(lb => lb.lbGuid === lbGuid))
        .filter(lb => lb);
      this.selectedLbGroupGuid = this.lbGroupGuid;
      this.isShowConfirmDialog = true;
    },
    pickOneGroupLbGroup(lbGroupGuid, lbStatusList) {
      if (lbStatusList && lbStatusList.length) {
        this.selectedLbList = lbStatusList
          // completed 的不包含在内
          .filter(
            lb =>
              !lb.status || lb.status.name !== caseStatusDict.completed.value
          );
        this.selectedLbGroupGuid = lbGroupGuid;
        this.isShowConfirmDialog = true;
      }
    },
    startLbTests() {
      // 重新设定 testLbList
      this.$store.dispatch("sca/setTestLbList", this.selectedLbList);
      this.$store.dispatch("sca/setLbGroupGuid", this.selectedLbGroupGuid);
      this.$router.push({ name: "scastepper" });
    },
    startLbTestsClosed() {
      this.selectedLbList = [];
      this.selectedLbGroupGuid = "";
    }
  },

  async created() {
    this.isLoading = true;
    await this.getPersonInfoInternal();
    await this.getLbGroupStatusList();
    this.isLoading = false;
  }
};
</script>
